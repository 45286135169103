import React, { useEffect } from 'react';

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';

// Components
import Layout from '../components/layout';
import AuthForm from "../components/user/authForm"
import Seo from '../components/global/seo';


const LoginPage = () => {
    const { setFooterHidden, setHeaderDark, setFooterTheme } = useSiteState();

    useEffect(() => {    
        document.documentElement.setAttribute('data-theme', `theme-black`);
        document.documentElement.style.setProperty(`--doc-bg`, '#141414');                  
        setHeaderDark(false)    
        setFooterHidden(false)
        setFooterTheme('theme-none')
    }, [setFooterHidden, setFooterTheme, setHeaderDark]);    
    
        
    return (
      <Layout>      
        
        <Seo title="App" metaTitle="App" />
        
        <div className="block w-full h-24 lg:h-header"></div>
        
        <section className="grid-std lg:items-start lg:relative px-gutter lg:px-0 mb-20 py-12 lg:py-20">
        
        <div className="col-span-full px-4 lg:px-0 lg:col-start-2 lg:col-end-14 prose max-w-none">
          <h1 className="mb-4">Account</h1>
          <p className="max-w-md">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porttitor id purus elementum dapibus.</p>
        </div>
        
        <div className="col-span-full px-4 lg:px-0 lg:col-start-15 lg:col-end-25">
          <AuthForm/>
        </div>
        
        </section>
    
    </Layout>
    )
}
export default LoginPage